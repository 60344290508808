import { CommonModule, NgFor } from '@angular/common';
import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    forwardRef,
    AfterContentChecked,
    AfterContentInit
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormModelService } from '../../../services/formModel/form-model.service';
import {GetInputLabelClass} from "../Helpers";

@Component({
    selector: 'app-text-area-field-test',
    standalone: true,
    imports: [FormsModule, MatFormFieldModule, MatInputModule, CommonModule],
    templateUrl: './text-area-field-test.component.html',
    styleUrl: "./text-area-field-test.component.css",
    providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextAreaFieldTestComponent),
        multi: true
    }
    ]
})
export class TextAreaFieldTestComponent implements ControlValueAccessor, OnInit, AfterContentChecked, AfterContentInit{
    @Input() fieldName: string = "";
    @Input() placeholder: string = "";
    @Input() readonly:boolean=false;
    @Input() value: string = "";
    @Input() errors: string[] = [];
    @Input() submitted: boolean = false;
    @Input() labelText:string = "";
    @Input() rows: number = 4;
    @Output() valueChange = new EventEmitter<string>;

    private formControl: NgControl|undefined;
    public isRequired: boolean= false;
    constructor(private _injector: Injector, private formService: FormModelService){}

    onChange: any = () => {};
    onTouched: any = () => {};

    ngOnInit(): void {
        this.formControl = this._injector.get(NgControl);
    }

    ngAfterContentInit() {
        if(this.fieldName!="" && !this.fieldName.includes("?")){
            this.fieldName+=":"
        }

        if(this.placeholder==""){
            this.placeholder = this.fieldName.replace("*", "");
        }
        this.isRequired = this.formControl?.control?.hasValidator(Validators.required) ?? false;
    }

    ngAfterContentChecked(): void {
        if(this.value!=""){
            this.handleNewValue(this.formControl?.value);
        }
    }

    writeValue(val: any): void {
        console.log(typeof val);
        if(typeof val === 'string' && val.length > 0) {
            this.value = val;
            console.log("writing")
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onValueChange(event: Event): void{
        const inputElement = event.target as HTMLInputElement;
        this.handleNewValue(inputElement.value);
    }

    handleNewValue(val:string){
        this.value = val;
        this.onChange(this.value);
        this.onTouched();
        this.valueChange.emit(this.value); // Text field not alway part of form
    }

    showError():boolean{
        if(this.submitted && this.formControl && this.formControl?.invalid){
            this.errors = this.formService.getErrorMsg(this.formControl)
            return true;
        }
        return false;
    }

    protected readonly GetInputLabelClass = GetInputLabelClass;

    ValueChange(val: string): void{
        this.handleNewValue(val);
    }
}
