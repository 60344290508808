<div class="dialogue-content">
    <h2 class="dialogue-title">Aanvraag {{getShorthandId(application.id)}}</h2>
    <div class="dialogue-sections">
        <div class="dialogue-section applicant-section">
            <h3 class="dialogue-section-title">{{applicant.name}} {{applicant.middleName}} {{applicant.surname}}</h3>
            <div class="field">
                <div>Id:</div>
                <b>{{ application.id }}</b>
            </div>
            <div class="field">
                <div>Naam: </div>
                <b>{{applicant.name+ " " +applicant.middleName+ " " +applicant.surname}}</b>
            </div>
            <div class="field">
                <div>Email: </div>
                <b>{{applicant.email}}</b>
            </div>
            <div class="field">
                <div>Telefoon: </div>
                <b>{{applicant.phonenumber}}</b>
            </div>
            <div class="field">
                <div>Geboortedatum: </div>
                <b>{{applicant.birthdate.substring(0,10)}}</b>
            </div>
            <div class="field">
                <div>BSN: </div>
                <b>{{applicant.bsn}}</b>
            </div>
            <div class="field">
                <div>Reden: </div>
                <b>{{application.reason+' op '+ application.situationStartDate.substring(0,10)}}</b>
            </div>
        </div>

        <div class="dialogue-section workperiod-list">
            <h3 class="dialogue-section-title">Gewerkte periodes in het buitenland</h3>
            <div *ngIf="workAbroadPeriods.length!=0; else NoInfo">
                <div class="list-section">
                    <div *ngFor="let workPeriod of workAbroadPeriods; index as i">
                        <mat-expansion-panel class="expansion-panel">
                            <mat-expansion-panel-header>
                                <div class="panel-header">
                                    <mat-panel-title>
                                        <b *ngIf="workAbroadPeriods.length>1"> Periode {{i+1}}</b>
                                    </mat-panel-title>
                                </div>
                            </mat-expansion-panel-header>
                            <div class="field">
                                <div>Startdatum: </div>
                                <div>{{workPeriod.startDate.substring(0,10)}}</div>
                            </div>
                            <div class="field">
                                <div>Einddatum: </div>
                                <div>{{workPeriod.endDate.substring(0,10)}}</div>
                            </div>
                            <div class="field">
                                <div>Land: </div>
                                <div>{{workPeriod.country}}</div>
                            </div>
                            <div class="field">
                                <div>Verzekeringsnummer: </div>
                                <div>{{workPeriod.ssn}}</div>
                            </div>
                            <div class="field">
                                <div>Dienstverband: </div>
                                <div>{{workPeriod.employmentType}}</div>
                            </div>
                            <div class="field">
                                <div>Handelsnaam: </div>
                                <div>{{workPeriod.tradeName}}</div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </div>
        </div>

        <div class="dialogue-section child-section">
            <h3 class="dialogue-section-title">Kinderen</h3>
            <div class="list-section">
                <div *ngFor="let child of children; index as i">
                    <mat-expansion-panel class="expansion-panel">
                        <mat-expansion-panel-header>
                            <div class="panel-header">
                                <mat-panel-title>
                                    <b> {{child.name+ " " +child.surname}}</b>
                                </mat-panel-title>
                            </div>
                        </mat-expansion-panel-header>
                        <b *ngIf="children.length>1">Kind {{i+1}}</b>
                        <div class="field">
                            <div>Naam: </div>
                            <b>{{child.name+ " " +child.surname}}</b>
                        </div>
                        <div class="field">
                            <div>Geboortedatum: </div>
                            <b>{{child.birthdate.substring(0,10)}}</b>
                        </div>
                        <div class="field">
                            <div>Geslacht: </div>
                            <b>{{child.gender}}</b>
                        </div>
                        <div class="field">
                            <div>Andere partner: </div>
                            <b>{{child.otherPartnerOfChild}}</b>
                        </div>
                        <div class="field">
                            <div>Andere partner werkt in het buitenland: </div>
                            <b>{{child.partnerWorksAbroad}}</b>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>

        <div class="dialogue-section partner-section">
            <h3 class="dialogue-section-title">Partner</h3>
            <div *ngIf="partner!=null; else NoInfo" >
                <div class="field">
                    <div>Naam: </div>
                    <b>{{partner.name+ " " +partner.middleName+ " " +partner.surname}}</b>
                </div>
                <div class="field">
                    <div>Geboorte: </div>
                    <b>{{(partner.birthdate).substring(0,10)+' te '+partner.birthplace+' '+partner.birthcountry}}</b>
                </div>
                <div class="field">
                    <div>Nationaliteit: </div>
                    <b>{{partner.nationality}}</b>
                </div>
                <div class="field">
                    <div>BSN: </div>
                    <b>{{partner.bsn}}</b>
                </div>
                <div class="field">
                    <div>Buitenlands verzekeringsnummer: </div>
                    <b>{{partner.ssn}}</b>
                </div>
                <div class="field">
                    <div>Geregistreerde partner: </div>
                    <b>{{partner.isRegistredPartner}}</b>
                </div>
                <div class="field">
                    <div>Startdatum partnerschap: </div>
                    <b>{{partner.dateOfPartnership.substring(0,10)}}</b>
                </div>
                <div class="field">
                    <div>Adres: </div>
                    <b>{{partner.address.street+' '+partner.address.number+', '+partner.address.postCode +', '+partner.address.place+' '+partner.address.country}}</b>
                </div>
            </div>
        </div>
        <div class="dialogue-section currentfinancialsupport-section">
            <h3 class="dialogue-section-title">Huidige uitkeringen</h3>
            <div class="list-section">
                <div *ngFor="let support of currentFinancialSupport; index as i">
                    <mat-expansion-panel class="expansion-panel">
                        <mat-expansion-panel-header>
                            <div class="panel-header">
                                <mat-panel-title>
                                    <b> {{support.financialSupportType}}</b>
                                </mat-panel-title>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="field">
                            <div>Type: </div>
                            <b>{{support.financialSupportType}}</b>
                        </div>
                        <div class="field">
                            <div>Startdatum: </div>
                            <b>{{support.startDate}}</b>
                        </div>
                        <div class="field">
                            <div>Land: </div>
                            <b>{{support.country}}</b>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>

    </div>



</div>
<ng-template #NoInfo>
    <div class="section-no-partner">
        Geen informatie
    </div>
</ng-template>
