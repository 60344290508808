<div class="{{sectionClass()}}">
    <div class="benefit-section__container">
        <div class="section benefit-section">
            <img src="assets/images/parent-child.svg" alt="" class="benefit-section__image" width=160>
            <div class="benefit-content">
                <div class="title-small">Uw kinderbijslag op kwartaalbasis</div>
                <div class="benefit-title">
                    <p>Dit is de kinderbijslag die u op kwartaalbasis zal ontvangen voor de kinderen in uw aanvraag.</p>
                    <p>Na het indienen van uw aanvraag stuurt een van onze specialisten u een uitnodiging voor het verkrijgen van de machtiging. Hierna kan de specialist uw aanvraag controleren en indienen.</p>
                </div>
                <div class="Benefit-overview">
                    <div *ngFor="let amount of receipt.amountsToReceive; index as i" class="receipt-row">
                        <div class="Benefit-row__child">{{i+1}}. Kinderbijslag kind {{amount.name}}</div>
                        <div class="Benefit-row__child-amount">€ {{(amount.amountToReceive/100).toString().replace('.',',')}}</div>
                    </div>
                </div>
                <div class="receipt-row">
                    <div >Uw totale kinderbijslag per kwartaal is:</div>
                    <div class="Benefit-row__child-amount">€ {{(receipt.totalAmountToReceive/100).toString().replace('.',',')}}</div>
                </div>
            </div>
        </div>
        <div class="costs-conditions__section">
            <div class="section">
                <form class="conditions-section" [formGroup]="formService.consentForm">
                    <div class="title-small">Voorwaarden<span class="input-label__required-star"> *</span></div>
                    <div class="condition-row">
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" formControlName="conditionsConsent">
                        <label for="vehicle1"> Ik ga akkoord met de <a href="algemene-voorwaarden" target="_blank">algemene voorwaarden.</a> en <a href="privacy" target="_blank">privacy statement</a> van Mijn Subsidie B.V.</label><br>
                    </div>
                    <div class="condition-row">
                        <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" formControlName="authorizationConsent">
                        <label for="vehicle2"> Ik ben op de hoogte van de regeling omtrend kinderbijslag en machtig Mijn Subsidie BV voor het doen van mijn aanvraag.</label><br>
                    </div>
                    <app-error *ngIf="showError()" [errors]="['Dit is een verplicht veld']"/>
                </form>
            </div>
            <div class="section">
                <div class="title-small">Verwerkingskosten</div>
                <div style="color: #666666; margin-bottom: 2rem; margin-top: .5rem">Eenmalige kosten voor de controle en verwerking aanvraag.</div>
                <div class="Benefit-overview">
                    <div class="receipt-row">
                        <div class="Benefit-row__child">1x Verwerking aanvraag kinderbijslag</div>
                        <div class="Benefit-row__child-amount">€ {{(receipt.totalPrice/100).toString().replace('.',',')}}</div>
                    </div>
                </div>

                <div class="receipt-row">
                    <div class="title-small">Totaal bedrag:</div>
                    <div class="title-small">€ {{(receipt.totalPrice/100).toString().replace('.',',')}}</div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="title-small">Betaalmethode</div>
            <div class="subtitle"> Selecteer de gewenste betaalmethode</div>
<!--
            <app-paymentmethod-field (valueChange)="onPaymentMethodChange($event)"/>
-->
            <div id="payment-form" #idealDiv>
                <label for="ideal-element"></label>
                <div id="ideal-element"><!-- Stripe Elements will be inserted here --></div>
                <!-- Used to display form errors. -->
            </div>
        </div>
    </div>
</div>
