<div class="step">
    <div class="form-container">
        <div class="field-group__container">
            <div class="field-group__section__header">Uw gegevens</div>
            <form class="field-group applicant-form" [formGroup]="formService.applicantForm" >
                <div class="field-group__row">
                    <app-text-field fieldName="Naam"
                                    formControlName="name"
                                    style="flex: 1"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Achternaam"
                                    formControlName="surname"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-text-field fieldName="Initialen"
                                    formControlName="initials"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Tussenvoegsels"
                                    formControlName="middleName"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-date-field fieldName="Geboortedatum"
                                    formControlName="birthdate"
                                    [submitted]="stepService.stepSubmitted[1]"
                                    style="width: calc(50% - .5rem)"
                    />
                    <app-text-field fieldName="Burgerservicenummer"
                                    formControlName="bsn"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-text-field fieldName="E-mailadres"
                                    formControlName="email"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Telefoon"
                                    formControlName="phonenumber"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
            </form>
        </div>
        <div *ngIf="formService.applicantHasPartner" class="field-group__container">
            <div class="field-group__section__header">Partner</div>
            <form class="field-group partner-form" [formGroup]="formService.partnerForm!">
                <div class="field-group__row">
                    <app-text-field fieldName="Voornaam"
                                    formControlName="name"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Achternaam"
                                    formControlName="surname"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-text-field fieldName="Initialen"
                                    formControlName="initials"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Tussenvoegsels"
                                    formControlName="middleName"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-date-field  fieldName="Geboortedatum"
                                     formControlName="birthdate"
                                     [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Geboorteplaats"
                                    formControlName="birthplace"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-select-field fieldName="Geboorteland"
                                      formControlName="birthcountry"
                                      [submitted]="stepService.stepSubmitted[1]"/>
                    <app-text-field fieldName="Burgerservicenummer partner"
                                    placeholder="Burgerservicenummer"
                                    formControlName="bsn"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-select-field fieldName="Nationaliteit"
                                      formControlName="nationality"
                                      [submitted]="stepService.stepSubmitted[1]"/>
                    <app-gender-field formControlName="gender"
                                      [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div class="field-group__row">
                    <app-dualoption-field fieldName="Bent u gehuwd of geregistreerd partner?"
                                          [duoOption]="['Ja', 'Nee']"
                                          formControlName="isRegistredPartner"
                                          [submitted]="stepService.stepSubmitted[1]"/>
                    <app-date-field *ngIf="isRegistredPartner()" fieldName="Sinds"
                                    formControlName="dateOfPartnership"
                                    [submitted]="stepService.stepSubmitted[1]"/>
                </div>
                <div *ngIf="showPartnerAddressForm()" class="field-group__container">
                    <div  class="field-group__section__header">Adresgegevens partner</div>
                    <form  class="field-group" formGroupName="address">
                        <div class="field-group__row">
                            <app-text-field fieldName="Straatnaam"
                                            formControlName="street"
                                            [submitted]="stepService.stepSubmitted[1]"/>
                            <app-text-field fieldName="Volledig huisnummer"
                                            placeholder="Huisnummer"
                                            formControlName="number"
                                            [submitted]="stepService.stepSubmitted[1]"/>
                            <app-text-field fieldName="Postcode"
                                            formControlName="postCode"
                                            [submitted]="stepService.stepSubmitted[1]"/>
                        </div>
                        <div class="field-group__row">
                            <app-text-field fieldName="Plaats"
                                            formControlName="place"
                                            [submitted]="stepService.stepSubmitted[1]"/>
                            <app-select-field fieldName="Land"
                                              formControlName="country"
                                              [submitted]="stepService.stepSubmitted[1]"/>
                        </div>
                        <app-text-field *ngIf="formService.partnerLivesAbroad"
                                        fieldName="Buitenlands verzekeringsnummer"
                                        placeholder="Verzekeringsnummer"
                                        formControlName="ssn"
                                        [submitted]="stepService.stepSubmitted[1]"/>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
