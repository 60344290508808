import {AfterContentChecked, AfterContentInit, Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators,ReactiveFormsModule, FormControl } from '@angular/forms';
import { TextFieldComponent } from '../../../../SharedComponents/fields/text-field/text-field.component';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { DateFieldComponent } from '../../../../SharedComponents/fields/date-field/date-field.component';
import { GenderFieldComponent } from '../../../../SharedComponents/fields/gender-field/gender-field.component';
import { DualoptionFieldComponent } from '../../../../SharedComponents/fields/dualoption-field/dualoption-field.component';
import { SelectFieldComponent } from '../../../../SharedComponents/fields/select-field/select-field.component';
import { StepService } from '../../../../services/step/step.service';
import {ApplicantModel, ApplicationModel, PartnerModel} from "../../../../models/applicant.model";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-step2',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TextFieldComponent,
    DateFieldComponent,
    GenderFieldComponent,
    DualoptionFieldComponent,
    SelectFieldComponent,
  ],
  templateUrl: './step2.component.html',
  styleUrl: '../../step.component.css'
})
export class Step2Component implements OnInit, AfterContentInit{

    @Input() dataApplicant: ApplicantModel | undefined;
    @Input() dataPartner: PartnerModel | undefined | null;

    constructor(public stepService: StepService, public formService: FormModelService, public fb: FormBuilder){
    }


    ngOnInit(): void {
        this.stepService.setStep2Form(this.dataApplicant, this.dataPartner);
    }

    ngAfterContentInit(): void {
        scrollToTop();
    }

    public isRegistredPartner(): boolean{
        if(this.formService.partnerForm==null){
            throw Error("partnerForm does not exist");
        }
        let result =  this.formService.partnerForm.value.isRegistredPartner=="Ja";
        if(!result){
            this.formService.partnerForm.value.dateOfPartnership="";
        }
        return result
    }

    public showPartnerAddressForm(): boolean{
        return !this.formService.partnerHasSameAddress() && this.formService.applicantHasPartner
    }
}
