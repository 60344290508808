import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { SelectFieldComponent } from '../../../../SharedComponents/fields/select-field/select-field.component';
import { TextFieldComponent } from '../../../../SharedComponents/fields/text-field/text-field.component';
import { DualoptionFieldComponent } from '../../../../SharedComponents/fields/dualoption-field/dualoption-field.component';
import { DateFieldComponent } from '../../../../SharedComponents/fields/date-field/date-field.component';
import { WorkcountryFieldComponent } from '../../../../SharedComponents/fields/workcountry-field/workcountry-field.component';
import { StepService } from '../../../../services/step/step.service';
import {MatTooltip} from "@angular/material/tooltip";
import {ApplicationModel, WorkAbroadPeriodModel} from "../../../../models/applicant.model";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-step4',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectFieldComponent,
    TextFieldComponent,
    DualoptionFieldComponent,
    DateFieldComponent,
    WorkcountryFieldComponent,
    MatTooltip,
  ],
  templateUrl: './step4.component.html',
  styleUrl: '../../step.component.css'
})
export class Step4Component implements OnInit, AfterContentInit{

    @Input() data: WorkAbroadPeriodModel[] | undefined;

    public String = String;

    constructor(public stepService: StepService, public formService: FormModelService){}

    ngOnInit(): void {
        this.stepService.setStep4Form(this.data);
    }

    ngAfterContentInit(): void {
        scrollToTop();
    }

    setOtherCountries(inputValue: string, periodForm: AbstractControl){
        (inputValue=="Nee") && this.formService.resetOtherCountries(periodForm);
    }

    public setWorkAbroad(val: string):void{
        if(val=="Ja"){
            // this.formService.addPeriodForm();
        }else{
            this.formService.removeAllPeriodForms()
        }
    }

    worksAbroad():boolean{
        return this.formService.PeriodForm.get("workedAbroad")?.value=="Ja";
    }
    employmentTypeChange(type: string, index:number){
        let employerControl = this.formService.periodForms.at(index).get("employer");
        if(type=="Loondienst"){
            let tradeNameControl = this.formService.periodForms.at(index).get("tradeName");
            tradeNameControl?.setValue("");
        }else if(type=="Zelfstandige"){
            let employerControl = this.formService.periodForms.at(index).get("employer");
            employerControl?.setValue("");
        }
    }
}
