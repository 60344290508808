import {AfterContentInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { StripeService } from '../../../../services/stripe/stripe.service';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from "../../../../SharedComponents/fields/text-field/text-field.component";
import { ReactiveFormsModule } from '@angular/forms';
import { StepService } from '../../../../services/step/step.service';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import {PaymentReceipt} from "../../../../models/payment.model";
import {ErrorComponent} from "../../../../SharedComponents/error/error.component";
import {CdkVirtualScrollableWindow} from "@angular/cdk/scrolling";
import {Data} from "@angular/router";
import {DataService} from "../../../../services/data/data.service";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
    selector: 'app-step-payment',
    standalone: true,
    templateUrl: './step-payment.component.html',
    styleUrls: ["./step-payment.component.css", "../../../../SharedComponents/step-field/step-field.component.css"],
    imports: [CommonModule, TextFieldComponent, ReactiveFormsModule, ErrorComponent]
})
export class StepPaymentComponent implements OnInit, AfterContentInit {
    public receipt: PaymentReceipt = new PaymentReceipt();
    @Input() public showStep: boolean = false;

    @ViewChild('cardDiv') cardDiv: ElementRef | undefined;
    @ViewChild('idealDiv') idealDiv: ElementRef | undefined;

    constructor(
        public stripeService: StripeService,
        public formService: FormModelService,
        public stepService: StepService,
        private dataService: DataService) {
    }

    async ngOnInit() {
        console.log("Payment Init")
        this.dataService.createPayment(this.stepService.applicationId).subscribe(
            async (res) =>{
                this.receipt = res;
                this.stripeService.receipt = res;
                await this.stripeService.initializeStripe();
            }
        )
    }

    ngAfterContentInit() {
        scrollToTop();
    }

    public sectionClass(){
        return this.showStep ? "step-container step-shown" : "step-container step-hidden";
    }

    showError() {
        return this.stepService.stepSubmitted[9] && this.formService.consentForm.invalid;
    }
}
