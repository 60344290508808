<form [formGroup]="formService.PeriodForm">
    <div class="step-header">
        <div class="field-group__container stepheader">
            <div class="section-header">Werken in het buitenland</div>
            <div class="field-group">
                <app-dualoption-field fieldName="Werkt u nu buiten Nederland?"
                                      formControlName="workedAbroad"
                                      [duoOption]="['Ja', 'Nee']"
                                      (inputChange)="setWorkAbroad($event)"
                                      [submitted]="stepService.stepSubmitted[3]"/>
            </div>
        </div>
    </div>
    <div formArrayName="periodForms" *ngIf="worksAbroad()">
        <div *ngFor="let PeriodForm of formService.periodForms.controls; index as i" [ngClass]="'step '+String(i+1)" [formGroupName]="i">
            <div class="form-container">
                <div class="field-group__container">
                    <div class="field-group__header" >
                        <div> Periode {{i+1}} in het buitenland</div>
                        <button *ngIf="i>0" class="delete-button" (click)="formService.removePeriodForm(i)">Periode verwijderen</button>
                    </div>
                    <div class="field-group">
                        <div class="field-group__row">
                            <app-date-field fieldName="Startdatum" formControlName="startDate" [submitted]="stepService.stepSubmitted[3]"/>
                            <app-date-field fieldName="Einddatum" formControlName="endDate" [submitted]="stepService.stepSubmitted[3]"/>
                        </div>
                        <div class="field-group__row">
                            <app-select-field fieldName="In welk land" formControlName="country" [submitted]="stepService.stepSubmitted[3]"/>
                            <app-text-field fieldName="Buitenlands verzekeringsnummer" placeholder="Verzekeringsnummer" formControlName="ssn" [submitted]="stepService.stepSubmitted[3]"/>
                        </div>
                        <div class="field-group__row">
                            <app-dualoption-field fieldName="Gewerkt als"
                                                  [duoOption]="['Loondienst', 'Zelfstandige']"
                                                  formControlName="employmentType"
                                                  (inputChange)="employmentTypeChange($event, i)"
                                                  [submitted]="stepService.stepSubmitted[3]"/>
                            <app-text-field *ngIf="!formService.isSelfEmployed(i)"
                                            fieldName="Naam werkgever"
                                            placeholder="Werkgever"
                                            formControlName="employer"
                                            [submitted]="stepService.stepSubmitted[3]"/>
                            <app-text-field *ngIf="formService.isSelfEmployed(i)"
                                            fieldName="Handelsnaam bedrijf"
                                            placeholder="Handelsnaam"
                                            formControlName="tradeName"
                                            [submitted]="stepService.stepSubmitted[3]"/>
                        </div>
                    </div>
                </div>
                <div class="field-group__container">
                    <div class="field-group__header"> Werken in meerdere landen</div>
                    <div class="field-group othercountries">
                        <app-dualoption-field [duoOption]="['Ja', 'Nee']"
                                              [fieldName]="'Heeft u in periode ' + String(i+1)+' in meerdere landen gewerkt (ook gecombineerd met Nederland)?'"
                                              formControlName="workedInOtherCountries"
                                              (inputChange)="setOtherCountries($event, PeriodForm)"
                                              [submitted]="stepService.stepSubmitted[3]"/>
                        <div *ngIf="formService.getWorkedInOtherCountry(i)=='Ja'" formArrayName="otherCountriesList">

                            <div class="composed-field" *ngFor="let workCountry of formService.getOtherCountries(i).controls; let j = index" [formGroupName]="j ">
                                <div class="land-percentage">
                                    <app-select-field style="display: flex; flex: 1" fieldName="Land" formControlName="country" [submitted]="stepService.stepSubmitted[3]"/>
                                    <app-text-field style="display: flex; flex: 1" fieldName="Percentage" formControlName="percentage" [submitted]="stepService.stepSubmitted[3]"/>
                                </div>
                                <div class='delete-country-button' (click)="formService.removeOtherCountry(i,j)">
                                    <img src="../../../../../assets/icons/minus.svg" height="32px" width="32px" alt="">
                                </div>
                            </div>
                            <div class="add-section" (click)="formService.addOtherCountry(i)">
                                <img src="../../../../../assets/icons/add.svg" alt="add"/>
                                <div>Voeg een land toe</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-section"
             (click)="formService.addPeriodForm()"
             matTooltip="Indien u de afgelopen 12 maanden een periode buiten Nederland heeft gewerkt"
             matTooltipPosition="above"
             matTooltipHideDelay="100">
            <img src="../../../../../assets/icons/add.svg" alt="add"/>
            <div>Voeg een periode toe</div>
        </div>
    </div>
</form>

