import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import { RadioFieldComponent } from "../../../../SharedComponents/fields/radio-field/radio-field.component";
import { DualoptionFieldComponent } from "../../../../SharedComponents/fields/dualoption-field/dualoption-field.component";
import { TextFieldComponent } from "../../../../SharedComponents/fields/text-field/text-field.component";
import { SelectFieldComponent } from "../../../../SharedComponents/fields/select-field/select-field.component";
import { DateFieldComponent } from "../../../../SharedComponents/fields/date-field/date-field.component";
import { FormModelService } from '../../../../services/formModel/form-model.service';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { StepService } from '../../../../services/step/step.service';
import { CommonModule } from '@angular/common';
import {MatTooltip} from "@angular/material/tooltip";
import {PreviousFinancialSupportModel} from "../../../../models/applicant.model";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-step8',
  standalone: true,
    imports: [CommonModule, RadioFieldComponent, DualoptionFieldComponent, TextFieldComponent, SelectFieldComponent, DateFieldComponent, ReactiveFormsModule, MatTooltip],
  templateUrl: './step8.component.html',
  styleUrl: '../../step.component.css',
})
export class Step8Component implements OnInit, AfterContentInit{
    @Input() data: PreviousFinancialSupportModel | undefined;
    constructor(public formService: FormModelService, public stepService: StepService ){}

    ngOnInit(): void {
        this.stepService.setStep8Form(this.data);
    }

    ngAfterContentInit(): void {
        scrollToTop();
    }

    protected readonly Validators = Validators;
}
