import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import { DualoptionFieldComponent } from "../../../../SharedComponents/fields/dualoption-field/dualoption-field.component";
import { StepService } from '../../../../services/step/step.service';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RadioFieldComponent } from '../../../../SharedComponents/fields/radio-field/radio-field.component';
import { DateFieldComponent } from "../../../../SharedComponents/fields/date-field/date-field.component";
import { SelectFieldComponent } from "../../../../SharedComponents/fields/select-field/select-field.component";
import {FinancialSupportModel} from "../../../../models/applicant.model";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
    selector: 'app-step7',
    standalone: true,
    templateUrl: './step7.component.html',
    styleUrl: '../../step.component.css',
    imports: [DualoptionFieldComponent, ReactiveFormsModule, CommonModule, RadioFieldComponent, DateFieldComponent, SelectFieldComponent]
})

export class Step7Component implements OnInit, AfterContentInit{
    @Input() data: FinancialSupportModel[] | undefined | null;

    public String = String;
    public options: string[] = ["WW-uitkering", "Bijstandsuitkering", "Arbeidsongeschiktheidsuitkering", "Pensioen"]
    constructor(public stepService: StepService, public formService: FormModelService){}
    ngOnInit(): void {
        this.stepService.setStep7Form(this.data);
    }

    ngAfterContentInit(): void {
        scrollToTop();
    }

    public setPartnerReceivesSupport(value:string){
        if(value=="Ja"){
            /*this.formService.addPartnerFinancialSupportForm()*/
        }else{
            this.formService.removeAllPartnerFinancialSupportForms();
        }
    }

    get receivesSupport():boolean{
        return this.formService.partnerFinancialSupportForm?.get("receivesSupport")?.value=="Ja";
    }

}
