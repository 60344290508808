import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import { StepService } from '../../../../services/step/step.service';
import {ApplicantModel, Application, ChildModel, PartnerModel} from '../../../../models/applicant.model';
import { CommonModule } from '@angular/common';
import { RequestbuttonComponent } from '../../../../SharedComponents/requestbutton/requestbutton.component';
import {FormModelService} from "../../../../services/formModel/form-model.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-step-overview',
  standalone: true,
  imports: [CommonModule, RequestbuttonComponent],
  templateUrl: './step-overview.component.html',
  styleUrl: './step-overview.component.css'
})
export class StepOverviewComponent implements OnInit, AfterContentInit {

    @Input() dataApplicant: ApplicantModel | undefined;
    @Input() dataPartner: PartnerModel | undefined | null;
    @Input() dataChildren: ChildModel[] | undefined;

    public applicantForm!: FormGroup;
    public partnerForm!: FormGroup | null;
    public childFormGroups!: FormArray;

    constructor(public formService: FormModelService, public stepService: StepService) {
    }

    ngOnInit(): void {
        console.log(this.formService.partnerForm?.value);
        if (this.dataApplicant != null && this.dataChildren != null) {
            this.stepService.setStepOverviewForm(this.dataApplicant, this.dataPartner, this.dataChildren);
        }
        this.applicantForm = this.formService.applicantForm;
        this.partnerForm = this.formService.partnerForm;

        this.childFormGroups = this.formService.childForms;
    }

    ngAfterContentInit() {
        scrollToTop();
    }

    applicantControlValue(controlName: string): string {
        return this.applicantForm != null ? (this.applicantForm?.get(controlName) as FormControl).value : "";
    }

    partnerControlValue(controlName: string) {
        return (this.partnerForm?.get(controlName) as FormControl).value;
    }

    getChildControlValue(index: number, controlName: string) {
        return this.formService.childForms.at(index).get(controlName)?.value;
    }
}
