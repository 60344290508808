import {Component, OnInit} from '@angular/core';
import {MatError, MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup} from '@angular/forms';
import { RequestbuttonComponent } from '../../SharedComponents/requestbutton/requestbutton.component';
import {FormModelService} from "../../services/formModel/form-model.service";
import {CommonModule, JsonPipe, NgIf} from "@angular/common";
import {ErrorComponent} from "../../SharedComponents/error/error.component";
import {DataService} from "../../services/data/data.service";
import {ContactModel} from "../../models/contact.model";
import {GetInputLabelClass} from "../../SharedComponents/fields/Helpers";
import {TextFieldComponent} from "../../SharedComponents/fields/text-field/text-field.component";
import {Router} from "@angular/router";
import {TextAreaFieldComponent} from "../../SharedComponents/fields/text-area-field/text-area-field.component";
import {TextFieldTestComponent} from "../../SharedComponents/fields/text-field-test/text-field-test.component";
import {
    TextAreaFieldTestComponent
} from "../../SharedComponents/fields/text-area-field-test/text-area-field-test.component";

@Component({
  selector: 'app-contact',
  standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatError, ReactiveFormsModule, RequestbuttonComponent, CommonModule, TextFieldTestComponent, TextAreaFieldTestComponent],
  templateUrl: './contact.component.html',
  styleUrls: ["./contact.component.css", "../Step/step.component.css"]
})
export class ContactComponent {

    public submitted: boolean  = false;

    constructor(public formService: FormModelService, private dataService: DataService, private route: Router) {}

    getContactControl(controlName: string): FormControl{
        return this.formService.contactForm.get(controlName) as FormControl;
    }

    sendMessage(){
        this.submitted = true;
        if(this.formService.contactForm.valid){
            let contact = this.formService.contactForm.value;
            delete contact["emailConfirmation"];
            this.dataService.sendContactMessage(this.formService.contactForm.value as ContactModel).subscribe(response => {
                console.log("response", response);
                this.route.navigate([""]);
            })
        }

    }

    protected readonly GetInputLabelClass = GetInputLabelClass;
    protected readonly JSON = JSON;
}
