import {Component, AfterViewChecked, OnInit, Input, AfterContentInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from '../../../../SharedComponents/fields/text-field/text-field.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { DateFieldComponent } from '../../../../SharedComponents/fields/date-field/date-field.component';
import { GenderFieldComponent } from '../../../../SharedComponents/fields/gender-field/gender-field.component';
import { RadioFieldComponent } from '../../../../SharedComponents/fields/radio-field/radio-field.component';
import { StepService } from '../../../../services/step/step.service';
import {SelectFieldComponent} from "../../../../SharedComponents/fields/select-field/select-field.component";
import {ApplicationModel, ChildModel} from "../../../../models/applicant.model";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-step3',
  standalone: true,
    imports: [
        CommonModule,
        TextFieldComponent,
        ReactiveFormsModule,
        DateFieldComponent,
        GenderFieldComponent,
        RadioFieldComponent,
        SelectFieldComponent,
    ],
  templateUrl: './step3.component.html',
  styleUrl: '../../step.component.css'
})
export class Step3Component implements OnInit, AfterViewChecked, AfterContentInit{
    @Input() data: ChildModel[] | undefined;

    public numChilderen: number = 1;
    public String=String;
    public newAdded: boolean = false;


    constructor(
        public stepService: StepService,
        public formService: FormModelService
    ){ }

    ngOnInit() {
        if(this.formService.childForms.controls.length==0){
            this.addChild(); // Standaard 1 childform
        }
        this.stepService.setStep3Form(this.data);
        if(this.formService.childForms.length==0){
            this.formService.addChildForm();
        }
    }

    ngAfterContentInit(): void {
        scrollToTop();
    }

    ngAfterViewChecked(){
        if(this.newAdded){
            let element = document.getElementsByClassName("step "+String(this.numChilderen));
            this.newAdded= false;
            element[0].scrollIntoView({ behavior: 'smooth' });
        }
    }
    question: string[] = [
        "Wie is de andere ouder van uw kind?",
        "Werkt de andere ouder nu buiten Nederland?",
        "Heeft de andere ouder in de laatste 12 maanden een periode buiten Nederland gewerkt?",
        "Waar verblijft het kind?"
    ]

    options: string[][] = [
        [
            "Mijn partner",
            "Iemand anders, namelijk..",
            "De andere ouder is niet bekend"
        ],
        ["Ja", "Nee", "Onbekend"],
        ["Ja", "Nee", "Onbekend"],
        ["Bij mij", "Bij mijn partner", "Bij de andere ouder", "Ergens anders"]
    ]

    addChild(){
    this.numChilderen++;
    this.newAdded = true;
    this.formService.addChildForm();
    }

    removeChild(index:number){
    this.formService.removeChildForm(index);
    this.numChilderen--;
    }
}
