import { Component } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { DataService } from '../../services/data/data.service';
import { Application } from '../../models/applicant.model';
import { CommonModule, JsonPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationDialogueComponent } from '../../SharedComponents/application-dialogue/application-dialogue.component';
import { TextFieldComponent } from '../../SharedComponents/fields/text-field/text-field.component';
import { FormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {getShorthandId} from "../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [MatTableModule, JsonPipe, CommonModule, FormsModule, NgxSkeletonLoaderModule, MatButtonModule, MatMenuModule],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent {

    public applications: Application[] = [];
    public filteredApplications: Application[] = [];
    public selectedApplication: Application | undefined;
    public currentFilter: string = "Compleet";
    public columns: string[] = ["Id", "Naam", "Initialen", "Email", "Telefoon", "Kind", "Kinderbijslag", "Aanvraagdatum"]
    public applicationState: string = ""
    public isLoading = true;
    public skeletonStyle = {
        'margin': '0px',
        'padding': '0px',
        'height': '2rem',
        'background-color': 'rgba(154, 176, 27, 0.3)'
    };
    public applicationStatus: string[] = ["Gegevens ontvangen", "Betaling mislukt", "Compleet", "Machtiging gestuurd", "Machtiging ontvangen", "Ingediend", "Geannuleerd"]

    constructor(private dataService: DataService, private dialog: MatDialog) {
        this.dataService.getApplications().subscribe({
            next: val => {
                this.isLoading = true;
                this.applications = this.sortedApplications(val);
                this.filteredApplications = val;
                this.isLoading = false;
                this.filterByStatus(["Compleet", "Machtiging ontvangen"]);
            },
            error: console.error
        })
    }

    handleClick($event: MouseEvent) {
        $event.stopPropagation();
    }

    public updateApplicationState(application: Application) {
        console.log(application);
        this.dataService.updateStateById(application.id, application.state).subscribe(
            respone => {
            },
            error => {
                console.error(error)
            }
        )
    }

    public getStateClass(state: string): string {
        switch (state) {
            case "Compleet": {
                return "application-state-select complete";
            }
            case "Ingediend": {
                return "application-state-select submitted";
            }
            case "Machtiging gestuurd": {
                return "application-state-select auth-sent";
            }
            case "Betaling mislukt": {
                return "application-state-select payment-failure";
            }
            case "Gegevens ontvangen": {
                return "application-state-select info-received";
            }
            case "Machtiging ontvangen": {
                return "application-state-select auth-received";
            }
            case "Geannuleerd": {
                return "application-state-select cancelled";
            }
            default: {
                return "";
            }
        }
    }

    public getClass(application: Application) {
        if (this.selectedApplication == application) {
            return "selected";
        }
        return "";
    }

    public handleApplicationClick(application: Application) {
        this.selectApplication(application);
        this.showApplicationDetails(application);
    }

    public selectApplication(application: Application) {
        this.selectedApplication = application;
    }

    public showApplicationDetails(application: Application) {
        const dialogRef = this.dialog.open(ApplicationDialogueComponent, {
            data: application
        });

        dialogRef.afterClosed().subscribe(_ => {
        })
    }

    public filterByStatus(statusses: string[]|string) {
        console.log(statusses);
        if(typeof statusses=="string"){
            this.currentFilter = statusses;
        }
        if ((typeof statusses == "string" && statusses == "Alles" || statusses.length == 0)) {
            this.filteredApplications = this.applications;
            console.log("filteredApplications Alles");
            return;
        } else {
            this.filteredApplications = typeof statusses == "string" ?
                this.applications.filter(application => application.state == statusses) :
                this.applications.filter(application => statusses.includes(application.state));
        }
    }

    private sortedApplications(Applications: Application[]): Application[] {
        return Applications.sort((a, b) => {
            return new Date(b.creationDateTime).getTime() - new Date(a.creationDateTime).getTime()
        })
    }

    public getCountForStatus(status: string): number {
        return this.applications.filter(application => application.state == status).length
    }

    protected readonly getShorthandId = getShorthandId;
    protected readonly Number = Number;
}
