import {AfterContentInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { SelectFieldComponent } from '../../../../SharedComponents/fields/select-field/select-field.component';
import { TextFieldComponent } from '../../../../SharedComponents/fields/text-field/text-field.component';
import { DualoptionFieldComponent } from '../../../../SharedComponents/fields/dualoption-field/dualoption-field.component';
import { DateFieldComponent } from '../../../../SharedComponents/fields/date-field/date-field.component';
import { WorkcountryFieldComponent } from '../../../../SharedComponents/fields/workcountry-field/workcountry-field.component';
import { StepService } from '../../../../services/step/step.service';
import { MatTooltip } from '@angular/material/tooltip';
import {WorkAbroadPeriodModel} from "../../../../models/applicant.model";
import {scrollToTop} from "../../../../SharedComponents/fields/Helpers";

@Component({
  selector: 'app-step5',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectFieldComponent,
    TextFieldComponent,
    DualoptionFieldComponent,
    MatTooltip,
    DateFieldComponent,
    WorkcountryFieldComponent,
  ],
  templateUrl: './step5.component.html',
  styleUrl: '../../step.component.css'
})
export class Step5Component implements OnInit, AfterContentInit{

    public String = String;
    @Input() public data: WorkAbroadPeriodModel[]|null|undefined = [];

    constructor(public stepService: StepService, public formService: FormModelService){}

    async ngOnInit() {
        //TODO: Weghalen!!
        this.formService.partnerPeriodForm = this.formService.createWorkAbroadForm();
        this.formService.addPeriodForm();

        if(!this.formService.applicantHasPartner){
            await this.stepService.nextStep();
        }
        this.stepService.setStep5Form(this.data);
    }

    ngAfterContentInit(): void {
        scrollToTop();
    }

    setOtherCountries(inputValue: string, periodForm: AbstractControl){
        (inputValue=="Nee") && this.formService.resetPartnerOtherCountries(periodForm);
    }

    public setWorkAbroad(val: string):void{
        if(val=="Ja"){
            // this.formService.addPartnerPeriodForm();
        }else{
            this.formService.removeAllPartnerPeriodForms()
        }
    }

    worksAbroad():boolean{
        return this.formService.partnerPeriodForm?.get("workedAbroad")?.value=="Ja";
    }
    employmentTypeChange(type: string, index:number){
        let employerControl = this.formService.partnerPeriodForms.at(index).get("employer");
        if(type=="Loondienst"){
            let tradeNameControl = this.formService.partnerPeriodForms.at(index).get("tradeName");
            tradeNameControl?.setValue("");
        }else if(type=="Zelfstandige"){
            let employerControl = this.formService.partnerPeriodForms.at(index).get("employer");
            employerControl?.setValue("");
        }
    }
}
