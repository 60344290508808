<div>
    <div class="home-section1">
        <div class="home-section1__overlay">
            <img class="home-section1__overlay-shape" src="../../../../../assets/icons/home-shape.svg" alt="" sizes="20px"/>
            <div class="home-section__content">
                <div class="home-section1__content">
                    <div class="home-section1__content_slogan">Laat uw kinderbijslag regelen door Mijn Bijslag</div>
                    <div class="home-section1__content_content">Gegarandeerd voor een foutloze en snelle afhandeling. Onze experts zorgen ervoor dat uw aanvraag compleet is en correct wordt ingediend zodat u de kinderbijslag zo snel mogelijk ontvangt. </div>
                    <app-requestbutton url="stap"/>
                </div>
            </div>
        </div>
    </div>
    <div class="home-section2-container">
        <div class="home-section__content">
            <div class="home-section2__image-container">
                <img class="home-section2__image" src="../../../assets/images/home2.png" alt="">
            </div>

            <div class="home-section2__text-container">
                <div class="home-section2__text">
                    <div class="title-medium">Kinderbijslag via Mijn Bijslag</div>
                    <div class="home-section2__text-paragraph">
                        <p>Via Mijn Bijslag is het mogelijk gemakkelijk kindertoeslag aan te vragen. Door onze expertise op het gebied van de regeling en het werken met het SVB kunnen wij uw aanvraag snel en correct indienen waardoor u gegarandeerd ben van de meest snelle en correcte verwerking.</p>
                        <p>Om voor uw aan de slag te gaan moeten wij van u een machtiging ontvangen voor het doen van uw aanvraag. Deze uitnodiging zal u automatisch ontvangen na het indienen van uw aanvraag.</p>
                    </div>
                    <div class="title-small">Voordelen van Mijn Bijslag</div>
                    <div class="list-items">
                        <li>
                            <img class="" src="../../../assets/icons/list-arrow.svg" alt="">
                            Correcte indiening door een van onze specialisten
                        </li>
                        <li>
                            <img class="" src="../../../assets/icons/list-arrow.svg" alt="">
                            Ondersteuning bij al uw vragen over kinderbijslag</li>
                        <li>
                            <img class="" src="../../../assets/icons/list-arrow.svg" alt="">
                            Snelste afhandeling van uw aanvraag</li>
                    </div>
                </div>
                <app-requestbutton url="stap"/>
            </div>
        </div>
    </div>
    <div class="home-section3-container">
        <div class="home-section3 home-section__content" >
            <div class="home-section3_header">
                <div class="title-medium">Hoe werken wij?</div>
                <div class="home-section3_header-text">Hoe werkt het aanvragen van uw kinderbijslag via Mijn Bijslag</div>
            </div>
            <div class="home-section3__steps">
                <div class="home-section3__step">
                    <div class="home-section3__step-image-container">
                        <img class="home-section3__step-image" src="../../../assets/images/home6.png" alt="">
                    </div>
                    <div class="home-section3__step-text">
                        <div class="title-small">Dien uw aanvraag in</div>
                        <div class="home-section3__step-text-content">Dien uw aanvraag volledig digitaal bij ons in. Indien er gegevens missen zal een van onze experts contact met u opnemen om alles compleet te maken.</div>
                    </div>
                </div>
                <div class="home-section3__step">
                    <div class="home-section3__step-image-container">
                        <img class="home-section3__step-image" src="../../../assets/images/home7.png" alt="">
                    </div>
                    <div class="home-section3__step-text">
                        <div class="title-small">Machtig een van onze experts</div>
                        <div class="home-section3__step-text-content">Na het toewijzen zal een van onze experts u een machtigingsverzoek sturen. Door middel van deze machtiging kunnen wij werkzaamheden voor u uitvoeren.</div>
                    </div>
                </div>
                <div class="home-section3__step">
                    <div class="home-section3__step-image-container">
                        <img class="home-section3__step-image" src="../../../assets/images/home9.png" alt="">
                    </div>
                    <div class="home-section3__step-text">
                        <div class="title-small">Wij dienen uw aanvraag in</div>
                        <div class="home-section3__step-text-content">Uw toegewezen specialist zorgt dat alle gegevens aanwezig en correct zijn na goedkeuring van het machtigingsverzoek en dient uw aanvraag vervolgens in.  </div>
                    </div>
                </div>
            </div>
            <app-requestbutton [light]="true" url="stap"/>
        </div>
    </div>
    <div class="home-section4-container">
        <div class="home-section__content">
            <div class="home-section4__image-container">
                <img class="home-section4__image" src="../../../assets/images/home10.png" alt="">
                <div class="home-section4__image-card">
                    <img class="home-section4__image-card-question-mark" src="../../../assets/icons/question-mark.svg" alt=""/>
                    <div class="title-small">
                        Staat uw vraag er niet tussen?
                    </div>
                    <div>
                        Bekijk alle meest gestelde vragen of neem direct contact met ons op
                    </div>
                    <a class="home-section4__image-card-button" href="faq">
                        <div> Naar Alle Meest Gestelde Vragen</div>
                        <img class="header-request-button-icon" src="../../../assets/icons/arrow-right.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="home-section4__text-container">
                <h3 class="title-medium">Meest gestelde vragen</h3>
                <div class="home-section4__text">Bekijk hier de meest gestelde vragen. Staat uw vraag er niet tussen? Neem dan direct contact met ons op.</div>
                <mat-expansion-panel *ngFor="let question of questions; index as i" class="expansion-panel" (opened)="expandPanel(i)" (closed)="collapsePanel(i)">
                    <mat-expansion-panel-header>
                        <div class="panel-header">
                            <mat-panel-title>
                                <div class="panel-header-content {{getExpansionClass(i)}}">{{question}}</div>
                            </mat-panel-title>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="panel-content" [innerHTML]="answers[i]"></div>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
    <app-request-section/>
</div>


<ng-template #shape >
    <div class="shape">
        <div class="shape-above"></div>
        <div class="shape-below">
            <div class="shape-below-left"></div>
            <div class="shape-below-right"></div>
        </div>
    </div>
</ng-template>
